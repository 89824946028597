



























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

interface Column {
  label: string;
  sortable?: boolean;
  sortBy?: string;
  align?: string;
  width?: string;
}

@Component
    export default class TableList extends Vue {
  @Prop() rows!: any;
  @Prop() groupedRows!: any;
  @Prop() groupedKey!: string;
  @Prop() additionalRowProperty!: string;
  @Prop() itemKey!: string;
  @Prop() columns!: {[key: string]: Column};
  @Prop({type: Boolean, default: false}) isClickable!: boolean;
  @Prop() rowHeight!: string;
  @Prop({type: Function}) groupedBy!: any;
  @Prop({type: String}) emptyText!: string;
  @Prop({
    type: Function,
    default: () => (): boolean => false
  }) isItemSelected!: (item: any) => boolean;


  private currentSort: string = "";
  private currentSortDir: string = "ASC";
  private activeGroups: string[] = [];

  created() {
    const key = this.$route.query.sortKey;
    if (key) {
      this.currentSort = key as string;
    }
    const dir = this.$route.query.sortDir;
    if (dir) {
      this.currentSortDir = dir as string;
    }
  }

  sort(key: string) {
    if (key === this.currentSort) {
      this.currentSortDir = this.currentSortDir === "ASC" ? "DESC" : "ASC";
    }
    this.currentSort = key;
    this.$router.push({query:{sortDir: this.currentSortDir, sortKey: this.currentSort}});
  }

  @Watch("sortedRows")
  sortedRowsChanged() {
      if(this.groupedRows && this.groupedRows.length > 0) {
          this.$nextTick(() => {
              this.activeGroups.forEach((group: string) => {
                  // @ts-ignore
                  this.$refs["groupedRow"+group][0].style.height = this.$refs["groupedRow"+group][0].children.length * 48 + "px";
              });
          });
      }
  }

  get sortedRows(): any {
    let rows: any = this.rows;
      if(this.groupedRows && this.groupedRows.length > 0) rows = this.groupedRows.map((row: any) => {
          row.groupedKey = row[this.groupedKey];
          return row;
      });
    if (this.currentSort) {
        let sorting: any = this.currentSort;
        if (this.columns[sorting].sortBy) sorting = this.columns[sorting].sortBy;
        return rows.sort((a: { [key: string]: string }, b: { [key: string]: string }) => {
            if (sorting === "date") {
                return this.getSortIndex(
                    this.parseDate(a[this.currentSort]),
                    this.parseDate(b[this.currentSort])
                );
            } else if (typeof sorting === "function") {
                return this.getSortIndex(sorting(a), sorting(b));
            } else {
                return this.getSortIndex(a[this.currentSort], b[this.currentSort]);
            }
        });
    } else return rows;
  }

  get isMobile() {
    return this.$store.state.isMobile;
  }

  private getSortIndex(a: string | number, b: string | number) {
    const sortIsAscending = this.currentSortDir === "ASC";
    
    // Special handling for volume sorting
    if (this.currentSort === "sizeValue") {
        // Convert values to MB for comparison
        const valueA = this.convertToMB(a as number, this.getUnitForValue(a));
        const valueB = this.convertToMB(b as number, this.getUnitForValue(b));
        
        if (valueA < valueB) {
            return sortIsAscending ? -1 : 1;
        }
        if (valueA > valueB) {
            return sortIsAscending ? 1 : -1;
        }
        return 0;
    }

    // Default sorting logic for other columns
    if (typeof a === "string" && typeof b === "string") {
        a = a.toLowerCase();
        b = b.toLowerCase();
    }
    if (a < b) {
        return sortIsAscending ? -1 : 1;
    }
    if (a > b) {
        return sortIsAscending ? 1 : -1;
    }
    return 0;
  }

  private convertToMB(value: number, unit: string): number {
    switch (unit.toUpperCase()) {
        case "GB":
            return value * 1024;
        case "TB":
            return value * 1024 * 1024;
        case "KB":
            return value / 1024;
        case "B":
            return value / (1024 * 1024);
        default: // MB or unknown
            return value;
    }
  }

  private getUnitForValue(value: any): string {
    // Find the corresponding row
    const row = this.rows.find((r: any) => r.sizeValue === value);
    return row ? row.sizeUnit : "MB"; // Default to MB if not found
  }

  private flexAlign(align: string): string {
    switch(align) {
      case "left": return "flex-start";
      case "center": return "center";
      case "right": return "flex-end";
      default: return align;
    }
  }

  private toggleOpen(key: any) {
     if(!this.activeGroups.includes(key)) this.activeGroups.push(key);
     else this.activeGroups.splice(this.activeGroups.indexOf(key), 1);
  }

  private beforeEnter(el: any) {
      el.style.height = "0";
  }
  private enter(el: any) {
      el.style.height = el.scrollHeight + "px";
  }
  private beforeLeave(el: any) {
      el.style.height = el.scrollHeight + "px";
  }
  private leave(el: any) {
      el.style.height = "0";
  }

  private parseDate(dateString: string): number {
    if (!dateString) return 0; // Handle empty values

    // Check if the date is in ISO format (e.g., "2023-12-18T12:00:10Z")
    if (dateString.includes("T") && dateString.includes("Z")) {
      return new Date(dateString).getTime();
    }

    const parts = dateString.split(" "); // Split into ["DATE"] or ["DATE", "HH:mm"]
    const datePart = parts[0]; // Date portion
    const timePart = parts.length === 2 ? parts[1] : "00:00"; // Default to midnight if no time is given

    let year, month, day;

    if (datePart.includes("-")) {
      const dateParts = datePart.split("-").map(Number);

      if (dateParts[0] > 31) {
        // YYYY-MM-DD format (ISO format)
        [year, month, day] = dateParts;
      } else {
        // DD-MM-YYYY format
        [day, month, year] = dateParts;
      }
    } else {
      return 0; // Invalid format
    }

    // Extract hours and minutes safely
    const [hours = 0, minutes = 0] = timePart.split(":").map(Number);

    return new Date(year, month - 1, day, hours, minutes).getTime(); // Convert to timestamp
  }

  get filteredRows(): any[] {
    return this.sortedRows;  // Using the existing sortedRows getter
  }

  get isAllSelected(): boolean {
    if (!this.rows || this.rows.length === 0) return false;
    return this.filteredRows.every((row: any) => this.isItemSelected(row));
  }

  get isPartiallySelected(): boolean {
    if (!this.rows || this.rows.length === 0) return false;
    const selectedCount = this.filteredRows.filter((row: any) => this.isItemSelected(row)).length;
    return selectedCount > 0 && selectedCount < this.filteredRows.length;
  }

  private toggleSelectAll(): void {
    this.$emit("toggle-select-all", this.filteredRows);
  }
}

