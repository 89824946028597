




































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { InventoryItem } from "@/interfaces/entities/InventoryItem";
import TableList from "@/components/partials/TableList.vue";
import TDSModal from "@/components/partials/TDSModal/TDSModal.vue";
import TDSButton from "@/components/common/TDSButton.vue";
import Inputfield from "@/components/partials/Inputfield.vue";
import SoldItemCardContent from "@/components/partials/YourStore/SoldItemCardContent.vue";
import TDSSpinner from "@/components/common/TDSSpinner.vue";
import { emojiFlag } from "@/util/emojiFlag";
import TDSSelect from "@/components/common/TDSSelect.vue";
import FilterPanel from "@/components/partials/FilterPanel.vue";
import { Filter } from "@/interfaces/entities/Filter";
import { Product } from "@/interfaces/entities/Product";
import TDSInputField from "@/components/common/TDSInputField.vue";
import { getFilteredProducts } from "@/util/filterUtils";

interface RouteParams {
    [key: string]: string;
}

@Component({
    components: {
        TDSInputField,
        FilterPanel,
        TDSSelect,
        TDSSpinner,
        TableList,
        TDSModal,
        TDSButton,
        Inputfield,
        SoldItemCardContent
    }
})
export default class Inventory extends Vue {
    private tableColumnsInventory: { [key: string]: any } = {
        amount: { label: this.$t("tableHeader.amt") as string, sortable: true, width: "60px", align: "left" },
        name: { label: this.$t("tableHeader.name") as string, sortable: true },
        coverage: { label: this.$t("tableHeader.coverage") as string, sortable: true, width: "90px", align: "right", sortBy: (product: Product) => product.countrySet },
        itemId: { label: this.$t("tableHeader.itemId") as string, sortable: false, width: "90px", align: "left" },
        sizeValue: { label: this.$t("tableHeader.volume") as string, sortable: true, width: "80px", align: "right" },
        validitySize: { label: this.$t("tableHeader.validity") as string, sortable: true, width: "100px", align: "right" },
        price: {
            label: this.$t("tableHeader.purchasePrice") as string,
            sortable: true,
            sortBy: (item: InventoryItem) => item.prices[0].priceValue,
            width: "120px",
            align: "right"
        },
        retailPrice: {
            label: this.$t("tableHeader.retailPrice") as string,
            sortable: true,
            sortBy: (item: InventoryItem) => item.retailPrices[0].priceValue,
            width: "120px",
            align: "right"
        },
        edit: { label: "", width: "60px", noBreak: true }
    };

    private inventoryItems: InventoryItem[] = [];
    private filteredItems: InventoryItem[] = [];
    private name: string = "";
    private id: string = "";
    private retailPrice: number | null = null;
    private showEditModal: boolean = false;
    private statusType: string = "";
    private statusModal: string | (string | null)[] | null = null;
    private isLoading: boolean = false;
    private isSavingEdit: boolean = false;
    private filters: { [key: string]: Filter } = {
        coverage: {
            val: "countrySet",
            key: "countrySet",
            label: (item: any) => this.$options.filters?.countryName(item.countrySet),
            placeholder: "All Coverage",
            icon: "mobile-data-grey24"
        },
        validity: {
            val: "validitySize",
            key: "validitySize",
            label: (item: any) => item.validitySize + " " + item.validityUnit,
            placeholder: "All Validities"
        },
        volumes: {
            compositeFields: ["sizeValue", "sizeUnit"],
            label: (item: any) => item.sizeValue + " " + item.sizeUnit,
            placeholder: "All Volumes",
            icon: "pricing-esim"
        }
    };

    get preparedInventoryItem() {
        return getFilteredProducts(this.inventoryItems);
    }

    get isMobile() {
        return this.$store.state.isMobile;
    }

    created() {
        document.title = process.env.VUE_APP_THEME_TITLE + " | " + this.$t("yourStore.inventory.pageTitle");
        this.statusModal = this.$route.query?.status || null;
        if (!this.statusModal) localStorage.removeItem("inventoryStatus");
        this.getStatusType();
        this.fetchData().then(() => {
            this.showModal(this.$route.params);
        });
    }

    @Watch("$route.params")
    paramsChanged(newParams: RouteParams) {
        this.showModal(newParams);
    }

    @Watch("$route.query")
    queryChanged() {
        this.statusModal = this.$route.query.status || null;
    }

    public getEmojiFlag(countryCode: string): string {
        return emojiFlag(countryCode, "🌏");
    }

    private async fetchData() {
        this.isLoading = true;
        try {
            this.inventoryItems = await this.$store.dispatch("FETCH_INVENTORY");
        } catch (e) {
            console.error("[Your Store Inventory] Failed fetching inventory items");
        } finally {
            this.isLoading = false;
        }
    }

    private showModal({ id }: RouteParams) {
        this.showEditModal = Boolean(id);
        if (id) {
            const item = this.inventoryItems.find((item: InventoryItem) => item.id.toString() === id);
            if (item) {
                this.name = item.name;
                this.retailPrice = +Number(item.retailPrices[0].priceValue ?? 0).toFixed(2);
                this.id = item.id;
            }
        }
    }

    private getStatusType() {
        this.statusType = localStorage.inventoryStatus || "";
    }


    public async saveData() {
        if (!(this.$refs.retailPriceInput as Inputfield)?.submit()) return;
        this.isSavingEdit = true;
        const item = this.inventoryItems.find((item: InventoryItem) => item.id.toString() === this.$route.params.id);
        if (item) {
            try {
                const priceChanged: boolean = item.retailPrices[0].priceValue !== this.retailPrice;
                await this.$store.dispatch("UPDATE_INVENTORY_ITEM", {
                    inventoryItemId: item.id,
                    name: this.name,
                    retailPrice: !priceChanged
                        ? undefined
                        : {
                              priceValue: this.retailPrice,
                              currencyCode: item.retailPrices[0].currencyCode
                          }
                });
                item.name = this.name;
                if (priceChanged) {
                    item.retailPrices[0].priceValue = this.retailPrice ?? 0;
                    item.retailPriceEdited = true;
                }
            } catch (e) {
                console.error("[Inventory] Error during saving retail price: ", e);
                this.$toastr.error(this.$t("yourStore.inventory.editFailed").toString());
            }
        }
        this.changeRoute("/your-store/inventory");
        this.isSavingEdit = false;
    }

    private changeRoute(path: string) {
        if (this.$route.query.sortDir && this.$route.query.sortKey) {
            this.$router.push({
                path: path,
                query: { sortDir: this.$route.query.sortDir, sortKey: this.$route.query.sortKey }
            });
        } else {
            this.$router.push(path);
        }
    }

    public async copyToClipBoard(text: string) {
        if (!text) return;
        await navigator.clipboard.writeText(text);
        this.$toastr.success(this.$t("components.inputfield.itemIdCopied"), true, true);
    }
}
