import {Product} from "@/interfaces/entities/Product";
import {InventoryItem} from "@/interfaces/entities/InventoryItem";

export function getFilteredProducts<T extends Product | InventoryItem>(items: T[]) {
    const worldwidePack = items.find((p: Product | InventoryItem) => p.countrySet === "WWW");

    const regionPacks = items.filter(
        (p: Product | InventoryItem) => (p.includedCountries ?? []).length > 1 && p.countrySet !== "WWW"
    );

    const singleCountryPacks = items.filter(
        (p: Product | InventoryItem) => (p.includedCountries ?? []).length <= 1
    );

    const sortedRegionPacks = [...regionPacks].sort((a: Product | InventoryItem, b: Product | InventoryItem) =>
        (a.countrySet ?? "").localeCompare(b.countrySet ?? "")
    );

    const sortedSingleCountryPacks = [...singleCountryPacks].sort((a: Product | InventoryItem, b: Product | InventoryItem) =>
        (a.countrySet ?? "").localeCompare(b.countrySet ?? "")
    );

    return [
        { isHeader: true, name: "Regions" },
        ...(worldwidePack ? [worldwidePack] : []),  // Worldwide comes first in the region list
        ...sortedRegionPacks,
        { isHeader: true, name: "Single Countries" },
        ...sortedSingleCountryPacks
    ];
}
